<template>
    <v-container fluid>
        <TheTitle :text="title" :icon="pageIcon">
            <v-row justify="start">
                <v-col md="8" sm="12" cols="12">
                    <v-text-field
                        placeholder="Procurar"
                        dense
                        solo
                        background-color="#FFFFFF"
                        hide-details
                        v-model="search"            
                    ></v-text-field>
                </v-col>
                <v-col md="4" sm="12" cols="12">
                    <TheButton
                        class="v-btn amber darken-4 d-flex align-center justify-center"
                        color="amber darken-4"
                        icon="mdi-plus"
                        text="Adicionar"
                        :dark="true"
                        @click="dialogSave()"
                    ></TheButton>
                    <TheButton
                        class="mt-3 v-btn success d-flex align-center justify-center"
                        :color="success"
                        icon="mdi-arrow-left"
                        text="Voltar"
                        :dark="true"
                        @click="$router.back()"
                    ></TheButton>
                </v-col>
            </v-row>
        </TheTitle>

        <v-card class="pa-5">
            <v-row>
                <v-col md="3" sm="12" cols="12">
                    <b>COD</b>
                    <TheInput
                        v-model="item.id"
                        :disabled="isDisabled"
                    ></TheInput>
                </v-col>
                <v-col md="9" sm="12" cols="12">
                    <b>Razão Social</b>
                    <TheInput
                        v-model="item.name"
                        :disabled="isDisabled"
                    ></TheInput>
                </v-col>
            </v-row>
        </v-card>

         <v-card class="pa-5 mt-5 ">
            <v-data-table 
                :headers="header" 
                :items="objects" 
                dense 
                locale="pt-BR" 
                :search="search" 
                :items-per-page="10"
            >
                <template v-slot:item.idData="{ item }">
                    {{ getDataName(item.idData) }}
                </template> 
                <template v-slot:[`item.action`]="{ item }">                
                    <TheToolTip label="Editar">
                        <v-btn icon small tile>
                        <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
                        </v-btn>
                    </TheToolTip>
                    <TheToolTip label="Remover">
                        <v-btn icon small tile>
                        <v-icon @click="remove(item.id)">mdi-minus</v-icon>
                        </v-btn>
                    </TheToolTip>
                </template>
            </v-data-table>
        </v-card>

        <TheDialog v-model="dialog" :title="title" :icon="pageIcon" width="600px">
            <template>
                <v-form v-model="valid" ref="form1">
                    <v-row>
                        <v-col md="12" sm="12" cols="12"> 
                            <b>Dados<sup>*</sup></b>
                            <TheSelect
                                v-model="object.idData"
                                :items="data"
                                class="mb-n6"
                                item-text="name"
                                item-value="id"                          
                                :requiredRule="[requiredRule]"
                            ></TheSelect>
                        </v-col>
                    </v-row>                   

                    <v-row>
                        <v-col cols="12">
                            <b>Observação </b>
                            <TheTextarea
                                v-model="object.observation"
                                class="mb-n6"
                            ></TheTextarea>
                        </v-col>
                    </v-row>                    
                </v-form>
            </template>
            <template v-slot:actions="">
                <v-btn
                    small
                    depressed
                    color="primary"                 
                    @click="resolveForm()"
                >
                    <span v-if="!edit"><v-icon small class="mr-2">mdi-plus</v-icon>Adicionar</span>
                    <span v-if="edit" ><v-icon small class="mr-2">mdi mdi-update</v-icon>Atualizar </span>
                </v-btn>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
            </template>
        </TheDialog> 
    </v-container>
</template>

<script>
    import axios from 'axios'
    import { baseApiUrl, showError } from '@/global'
    import TheTitle from '@/components/TheTitle'
    import TheDialog from '@/components/TheDialog'
    import TheToolTip from '@/components/TheToolTip'
    import TheSelect from '@/components/TheSelect'
    import TheTextarea from '@/components/TheTextarea'
    import TheInput from '@/components/TheInput'
    import TheButton from '@/components/TheButton'    

    export default {
        name: 'Dados',
        components: {
            TheTitle,
            TheDialog,
            TheToolTip,
            TheSelect,
            TheTextarea,
            TheInput,
            TheButton,
        },
       
        data: () => ({
            baseRoute: 'datagroup',
            title: 'Dados do Titular',
            pageIcon: 'mdi mdi-database-outline',
            
            item: [],
            objects: [],
            data: [],
            object: {},
            
            valid: false,
            dialog: false,
            search: null,
            error: false,
            edit: false,

            isDisabled: true,
            success: 'success',
            placeholder: 'Procurar',

            header: [
                { text: 'Dados', value: 'idData' },
                { text: 'Ações', value: 'action', width: '10%' },
            ],
        }),

        computed: {

            getDataName() {
                return function (idData) {
                    const data = this.data.find((el) => el.id === idData);
                    if (data) {
                        return data.name;
                    } else {
                        return "";
                    }
                };
            },
        },

        methods: {
            loadItem() {
                const id = this.$route.params.id

                this.$store.dispatch("setLoadingInfo", true)
                const url = `${baseApiUrl}/${this.baseRoute}/${id}`
                axios
                    .get(url)
                    .then((res) => {
                        this.$store.dispatch("setLoadingInfo", false)
                        this.item = res.data
                    })
                    .catch(showError)
            },

            loadData(){
                const id = this.$route.params.id
                this.$store.dispatch("setLoadingInfo", true)
                const url = `${baseApiUrl}/${this.baseRoute}/search/${id}/group-data`

                axios
                    .get(url)
                    .then((res) => {
                        this.$store.dispatch("setLoadingInfo", false)
                        this.objects = res.data
                    })
            },

            loadDatas() {
                const url = `${baseApiUrl}/data`
                axios
                    .get(url)
                    .then((res) => {
                        this.data = res.data
                    })
                    .catch(showError)
            },
            
            dialogSave() {
                this.object = {}
                this.edit = false
                this.dialog = true
            },

            dialogUpdate(item) {
                this.object = item;
                this.edit = true
                this.dialog = true         
            },  

            resolveForm(){
                this.$refs.form1.validate()
                if (!this.valid){
                    this.$store.dispatch("setErrorInfo", {
                        open: true,
                        text: "Os campos não foram preenchidos corretamente, você pode verificar?",
                        button: "Ok!",
                    })
                    return
                }
                if(this.edit){
                    this.update()
                } else {
                    this.save()
                }
            },
            
            save(){
                this.error = false
                const url = `${baseApiUrl}/${this.baseRoute}/data`
                const objects = this.object  
                objects.idDatagroup = this.item.id

                axios
                    .post(url, objects)
                    .then(() => this.loadData())
                    .catch(showError)
                    .finally(() => {
                        this.dialog = false
                    })    
                    
                this.loadData()
            },

            update() {
                this.error = false

                const id = this.object.id
                const url = `${baseApiUrl}/${this.baseRoute}/data/${id}`

                const objects = this.object

                delete objects.id
                delete objects.createdAt
                delete objects.updatedAt
                delete objects.deletedAt
                
                axios
                    .put(url, objects)
                    .then(() => this.loadData())
                    .catch(showError)
                    .finally(() => {
                        this.dialog = false
                    })
            },
                
            remove(id) {
                if (confirm("Remove?")) {
                    const url = `${baseApiUrl}/${this.baseRoute}/data/${id}`
                    axios
                    .delete(url)
                    .then(() => this.loadData())
                    .catch(showError)
                }
            },
        },

        mounted(){
            this.loadItem()
            this.loadData()
            this.loadDatas()
        },

    }

</script>
<style>
.full-height {
  height: 60vh;
}
</style>