<template>
  <div style="display:inline-block">
    <v-btn
      :color="color"
      :loading="loading"
      depressed
      
      
      @click="clicked"
      class="text-center border-right-white"
      :dark="dark"
    >
      <v-icon>{{ icon }}</v-icon
      >{{ text }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'TheButton',
  props: {
    color: {
      type: String,
      // default: 'primary',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: String,
    text: String,
    click: Function,
    dark: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    clicked() {
      this.$emit('click', this.click)
    },
  },
}
</script>

<style>
.border-right-white {
  margin-left:1px;
}
</style>
