<template>
    <v-select 
        class="mt-2"
        dense
        outlined
        tile
        color="primary"
        :placeholder="placeholder"
        :items="items" 
        :value="value" 
        :label="label" 
        :item-text="itemTextProperty"
        :item-value="itemValueProperty"
        :rules="requiredRule"
        @input="$emit('input', $event)"
    ></v-select>
</template>
  
<script>
export default {
    name: 'TheSelect',
    props: {
        items: Array,
        value: null,
        label: String,
        itemText: String,
        itemValue: String,
        requiredRule: Array,
        placeholder: {
            type: String,
            default: "Selecione", 
        },
    },

    data() {
        return {
            // selectedItem: null,
        };
    },
    computed: {
        itemTextProperty() {
            return this.itemText || "text"; 
        },
        itemValueProperty() {
            return this.itemValue || "value";
        },
    },
};
</script>
  